declare global {
  interface Window {
    REACT_APP_API_HOST: any;
    REACT_APP_API_BASE_PATH: any;
    REACT_APP_AUTH_MODE: any;
    REACT_APP_API_TOKEN: any;
    REACT_APP_CONTRACTMETADATAKEY_TO_DISPLAY: any;
  }
}

export const config = {
    contractMetadataKey: window.REACT_APP_CONTRACTMETADATAKEY_TO_DISPLAY || process.env.REACT_APP_CONTRACTMETADATAKEY_TO_DISPLAY,
  apiUrl: window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST,
  basePath: window.REACT_APP_API_BASE_PATH || process.env.REACT_APP_API_BASE_PATH,
  authMode: window.REACT_APP_AUTH_MODE || process.env.REACT_APP_AUTH_MODE,
  apiToken: window.REACT_APP_API_TOKEN || process.env.REACT_APP_API_TOKEN,
};
